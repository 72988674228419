import '../../../styles.css'
import 'bootstrap/dist/css/bootstrap.min.css'
// import { Spinner } from 'react-bootstrap';
import EmployeePlaceholder from '../../../svg/EmployeePlaceholder.svg'


const Card = (props) => {

    const {
        employee
    } = props


    return (
        <>
            <div className="card-body">
                <>
                    <div className='image-div'><img className='image' alt='' src={employee.ImagePath_VC || EmployeePlaceholder} /></div>
                    <div className='name-div'>
                        <a className='employee-name' href={employee.mailToLink} target="_blank" rel="nofollow noreferrer" title="Send Email">{employee.FirstName_VC} {employee.LastName_VC}</a>
                    </div>
                    <div className='italic-card-info'>
                        {employee.JobTitle_VC && employee.JobTitle_VC !== "" && <p className='division-title'>{`${employee.JobTitle_VC}`}</p>}
                        {employee.Division_ID && employee.Division_ID !== "" && <p className='division-title'>{`${employee.Division_ID} - ${employee.Division_VC}`}</p>}
                    </div>
                    <div>Mobile: {(employee.CellPhone_VC && employee.CellPhone_VC !== "") ? `${employee.CellPhone_VC}` : 'Unavailable'}</div>
                    <div>Direct: {(employee.OfficePhone_VC && employee.OfficePhone_VC !== "") ? `${employee.OfficePhone_VC}` : 'Unavailable'}</div>
                    <div>Extension: {(employee.OfficeExtension_VC && employee.OfficeExtension_VC !== "") ? `${employee.OfficeExtension_VC}` : 'Unavailable'}</div>

                </>
            </div >
        </>
    )
}

export default Card;