import React from 'react';
import { Route, Routes } from 'react-router-dom';

import LoginComponent from './LoginComponent';
import { EmployeeDirectory } from './employeeDirectory';


import './styles.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { clearEmailToken, clearAccessToken } from './services/User';

const AppWrapper = () => {

    const handleLogOut = async (e) => {
        console.log('logout')
        e.preventDefault()
        clearEmailToken()
        clearAccessToken()
    }

    return (
        <div style={{ background: "rgb(240,242,244", height: "100vh" }}>

            <div className="content-routes">
                <Routes>
                    <Route exact path="login" element={<LoginComponent />} />
                    <Route exact path="/" element={<EmployeeDirectory handleLogOut={handleLogOut} />} />
                </Routes>
            </div>
        </div>
    );
}

export default AppWrapper;
