import React from "react";
import EmployeeDirectoryInterface from "./EmployeeDirectoryInterface";
import { Navigate } from "react-router-dom";
import { getAccessToken, getEmail } from '../../services/User';

// const tabs = [
//     {
//         key: 'employeeManager',
//         label: 'Employee Manager'
//     }
// ];

const EmployeeDirectory = (props) => {
    const {
        handleLogOut
    } = props;
    // const [activeTab, setActiveTab] = useState('employeeManager');
    const activeTab = 'employeeDirectory'
    const token = getAccessToken();
    const userEmail = getEmail();
    console.log(userEmail)

    if (token === null || token === '') {
        return <Navigate to='/login' />
    }

    return (
        <div>
            {activeTab === 'employeeDirectory' && (
                <EmployeeDirectoryInterface handleLogOut={handleLogOut} />
            )}
        </div>
    )
}

export default EmployeeDirectory;