/* Employee Directory 
    Uses the asset-employee-gql backend service
    Simple one page application that shows all active ServiTech employees
    and allows user to sort by division, sort alphabetically, and search for 
    employees. Clicking employee name opens new email to selected employee.
    Used in SeriTech Intranet */

import { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../../styles.css'
import Card from './Card';
import { Row, Spinner, Modal } from 'react-bootstrap'
import ServitechLogoColor from '../../../svg/ServiTechColor.svg'
import { Link } from 'react-router-dom';


// For local testing
// const managerGql = 'http://localhost:4000/graphql'

// For deploy
const managerGql = 'https://asset-employee-gql-bjudfyf5za-uc.a.run.app/graphql'

const mailToTemplate = "mailto:"

function EmployeeDirectoryInterface(props) {
    const {
        handleLogOut
    } = props

    // Data Lists
    /* employeeList is fetched once, holds ALL ACTIVE employees, not updated
       filteredEmployeeList is the list of cards shown, will be sorted and filtered based on user selections */
    const [employeeList, setEmployeeList] = useState([]);
    const [divisionList, setDivisionList] = useState([]);
    const [filteredEmployeeList, setFilteredEmployeeList] = useState([]);
    const [employeesInDivisions, setEmployeesInDivisions] = useState([]);

    /* Loading states, sortingByDivision (used to know if user selected sort by divisions 
        for filter functionality), search strings (changedString is after user types in, set to 
        applied string after user hits search button) */
    const [loadingEmployees, setLoadingEmployees] = useState(false);
    const [sortingByDivision, setSortingByDivision] = useState(false);
    const [search, setSearch] = useState({
        appliedString: '',
        changedString: ''
    });

    // For search modal
    const [showSearchModal, setShowSearchModal] = useState(false);
    const handleCloseSearchModal = () => setShowSearchModal(false);
    const handleShowSearchModal = () => setShowSearchModal(true);
    let s = []


    // Sorts inputted employees alphabetically and returns sorted array
    async function sortCardsAlphabetically(employees) {
        setSortingByDivision(false)

        const sortedFirst = employees.sort((a, b) => {
            if (a.FirstName_VC < b.FirstName_VC) {
                return -1;
            } else if (a.FirstName_VC > b.FirstName_VC) {
                return 1;
            } else {
                return 0
            }
        });
        const sortedData = sortedFirst.sort((a, b) => {
            if (a.LastName_VC < b.LastName_VC) {
                return -1;
            } else if (a.LastName_VC > b.LastName_VC) {
                return 1;
            } else {
                return 0
            }
        });
        return (sortedData)
    };

    // Sorts inputted employees by division and returns sorted array
    async function sortCardsByDivision(employees) {
        setSortingByDivision(true)
        let finalArr = []
        const employeeDivisions = [...divisionList]
        employeeDivisions.forEach((division) => {
            finalArr.push({ Division_ID: division.Division_ID, Division_VC: division.Division_VC, employees: [] })
        })
        console.log("finalArr", finalArr)

        employees.forEach((employee) => {
            let index = -1
            const employeeDivisionID = employee.Division_ID
            employeeDivisions.forEach(function (item, i) {
                if (item.Division_ID === employeeDivisionID) {
                    console.log(i)
                    index = i;
                    return i
                }
            });
            if (index !== -1) {
                finalArr[index].employees.push(employee)
            }

        });
        // Sort Divisions by their Division ID

        // Sort Employees within each Division by Last Name, First Name
        const finishedSort = finalArr.map((division) => {
            const sortedFirst = division.employees.sort((a, b) => {
                if (a.FirstName_VC < b.FirstName_VC) {
                    return -1;
                } else if (a.FirstName_VC > b.FirstName_VC_VC) {
                    return 1;
                } else {
                    return 0
                }

            });
            const sortedEmployees = sortedFirst.sort((a, b) => {
                if (a.LastName_VC < b.LastName_VC) {
                    return -1;
                } else if (a.LastName_VC > b.LastName_VC) {
                    return 1;
                } else {
                    return 0
                }
            });
            return { "Division_ID": division.Division_ID, "Division_VC": division.Division_VC, "employees": sortedEmployees }
        })
        return (finishedSort)
    };

    /* Called once at page load to fetch all employees and populate employeeList
        Also fills filteredEmployeeList (defaults to Alphabetical sort)
        Also gets Divisions to fill DivisionList */
    async function getEmployees() {
        setLoadingEmployees(true)

        // Employees
        const getEmployeesQuery = `
            {
                getPeopleForEmployeeDirectory 
                {
                    People_ID
                    Active_BT 
                    FirstName_VC
                    LastName_VC 
                    Email_VC
                    JobTitle_VC 
                    CellPhone_VC
                    OfficePhone_VC 
                    OfficeExtension_VC 
                    Division_ID 
                    Division_VC
                    ImagePath_VC 
                    PIActive_BT
                }
            }
            `;
        const getEmployeesFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: getEmployeesQuery }), headers: { 'Content-Type': 'application/json' } });
        const getEmployeesJson = await getEmployeesFetch.json();
        const employees = getEmployeesJson.data.getPeopleForEmployeeDirectory
        employees.forEach((employee) => {
            if (employee.Active_BT === "1" || employee.Active_BT === 1 || employee.Active_BT === true || employee.Active_BT === "true") {
                employee.status = "1"
                employee.statusString = "Active"
            } else if (employee.Active_BT === "0" || employee.Active_BT === 0 || employee.Active_BT === false || employee.Active_BT === "false") {
                employee.status = "0"
                employee.statusString = "Inactive"
            }

            if (employee.Email_VC && employee.Email_VC !== null && employee.Email_VC !== '') {
                employee.mailToLink = mailToTemplate + employee.Email_VC
            }

            let city = ''
            let state = ''
            if (employee.PhysicalCity_VC && employee.PhysicalCity_VC !== "" && employee.PhysicalCity_VC !== undefined && employee.PhysicalCity_VC !== null) {
                city = employee.PhysicalCity_VC
            }
            if (employee.PhysicalState_VC && employee.PhysicalState_VC !== "" && employee.PhysicalState_VC !== undefined && employee.PhysicalState_VC !== null) {
                state = employee.PhysicalState_VC
            }
            if (city !== '' && state !== '') {
                employee.physicalLocation = `${city}, ${state}`
            } else if (city !== '' && state === '') {
                employee.physicalLocation = city
            } else if (city === '' && state !== '') {
                employee.physicalLocation = state
            } else {
                employee.physicalLocation = ''
            }



        })
        const sortedEmployees = await sortCardsAlphabetically(employees)

        // Divisions
        const getDivisionsQuery = `
             {
                 getDivisions 
                 {
                    Division_ID
                    Division_VC
                    Deactivated_BT
                 }
             }
             `;
        const getDivisionsFetch = await fetch(managerGql, { method: 'POST', body: JSON.stringify({ query: getDivisionsQuery }), headers: { 'Content-Type': 'application/json' } });
        const getDivisionsJson = await getDivisionsFetch.json();
        const divisions = getDivisionsJson.data.getDivisions

        let activeDivisions = []
        divisions.forEach((division) => {
            if (division.Deactivated_BT === 0 || division.Deactivated_BT === "0" || division.Deactivated_BT === false || division.Deactivated_BT === "false") {
                activeDivisions.push(division)
            }
        })

        setEmployeeList(sortedEmployees);
        setFilteredEmployeeList(sortedEmployees);
        setDivisionList(activeDivisions);
        setLoadingEmployees(false)
    };

    // Handler for menu button sorts. Takes employeeList (ALL employees), sorts them, and
    // sets filteredEmployeeList to sorted list
    async function handleSort(sortType) {
        // Sorts show ALL employees sorted (clears search) 
        let sortedCards = []
        const allEmployees = [...employeeList]
        if (sortType === "Division") {
            sortedCards = await sortCardsByDivision(allEmployees)
            setEmployeesInDivisions(sortedCards)
        } else if (sortType === "Alphabetical") {
            sortedCards = await sortCardsAlphabetically(allEmployees)
            setFilteredEmployeeList(sortedCards)
        }

        // Clear search when you select a new sort option
        setSearch({
            appliedString: '',
            changedString: ''
        })

    };

    // Handler for search key up - saves string to search.changedString on key up
    async function handleOnChangeSearch(e) {
        e.preventDefault();
        const value = e.target.value;
        setSearch(prevState => {
            return { ...prevState, changedString: value }
        });
    }

    // Helper function for search
    function getNestedStrings(obj, level) {
        if (obj) {
            if (level === 1) { s = [] }  // at parent level, start with an empty array
            const valArr = Object.values(obj);  // get array of object property values
            const strArr = valArr.filter((o) => { return (typeof o === 'string') });  // filter array for strings
            const objArr = valArr.filter((o) => { return (typeof o === 'object') });  // filter array for objects
            if (strArr.length > 0) { s = [...s, ...strArr]; };  // append strings to result array
            objArr.forEach((x) => { getNestedStrings(x, level + 1) });  // recurse through objects collecting child strings
            if (level === 1) { return s; };  // at parent level, return the string array
        }
    };

    // Handles applying search string (when user hits search button)
    async function handleSearch() {
        console.log('here')
        const searchString = search.changedString
        console.log(searchString)
        if (searchString.length) {
            const res = (employeeList.filter((x) => {  // filter resources for object(s) with strings that include searchStr
                return (getNestedStrings(x, 1).some((y) => {
                    return (y.toLowerCase().includes(searchString.toLowerCase()))
                }))
            }));

            if (sortingByDivision) {
                const divRes = await sortCardsByDivision(res)
                setEmployeesInDivisions(divRes)
            } else {
                setFilteredEmployeeList(res)
            }

            // save current search string to search.appliedString, reset search.changedString
            setSearch(prevState => {
                return { ...prevState, appliedString: search.changedString, changedString: "" }
            })
            handleCloseSearchModal()
        }
    }

    // Handler for Clear search button - called on click of Clear button in search modal
    async function handleClearSearch() {
        setSearch({ appliedString: '', changedString: '' })
        if (sortingByDivision) {
            const employees = await sortCardsByDivision(employeeList)
            setEmployeesInDivisions(employees)
        } else {
            setFilteredEmployeeList(employeeList)
        }
    }

    // Initial useEffect - gets data
    useEffect(() => {
        getEmployees()
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <nav className="navbar ST-navbar fixed-top">
                <div className="container-fluid">
                    <div>
                        {/* eslint-disable-next-line */}
                        <a className="navbar-brand" href="#"><img src={ServitechLogoColor} height="35px" /></a>
                    </div>
                    <div className="service-title">Employee Directory</div>
                    <div className="ps-5 pe-0">
                        {/* eslint-disable-next-line */}
                        <a className="navbar-nav" data-bs-toggle="dropdown">
                            <i className="bi bi-three-dots" style={{ "fontSize": "1.5rem", "color": "#000000", "cursor": "pointer" }}></i>
                        </a>
                        <ul className="me-0 dropdown-menu dropdown-menu-end">
                            <li className='dropdown-item' onClick={() => handleSort("Alphabetical")}>All Employees A-Z</li>
                            <li className='dropdown-item' onClick={() => handleSort("Division")} >All Employees By Division</li>
                            <li className='dropdown-item' onClick={() => handleShowSearchModal()} >Search</li>

                            <li><hr className="dropdown-divider" /></li>

                            <li><a className="dropdown-item" href="https://employees.servitech.com">Employee Manager</a></li>
                            <li><a className="dropdown-item" href="https://cropfile.servitech.com">The Crop File</a></li>
                            <li><a className="dropdown-item" href="https://labreports.servitechlabs.com/">Lab Reports</a></li>
                            <li><a className="dropdown-item" href="https://intranet.servitech.com">ServiTech Intranet</a></li>

                            <li><hr className="dropdown-divider" /></li>

                            <li className="dropdown-item" onClick={(e) => handleLogOut(e)}><Link to="/login" style={{ color: "black", textDecoration: "none" }}>Logout</Link></li>
                        </ul>
                    </div>
                </div>
            </nav>

            {/* Search modal - opens when user clicks Search in the hamburger menu */}
            <Modal show={showSearchModal} id="search-modal" aria-hidden="true" aria-labelledby="Save Changes?" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered save-changes">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5">Search Employees</h1>
                            <button type="button" className="btn-close" onClick={() => handleCloseSearchModal()}></button>
                        </div>
                        <div className="modal-body">
                            <div className="mt-0 search-modal-content">
                                <input type='text' className='search-box' value={search.changedString} placeholder={search.appliedString || "Start typing to search"} onChange={(e) => handleOnChangeSearch(e)}></input>
                                <button type='button' className="button-pill" onClick={() => handleSearch()}>Search</button>
                                <button type='button' className="button-pill-caution" onClick={() => handleClearSearch()}>Clear</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <div className='grid-container'>
                <Row className='grid'>
                    {/* Loading spinner */}
                    {loadingEmployees &&
                        <div className='loading-employees-spinner'>
                            Loading Employees <Spinner />
                        </div>
                    }

                    {/* Maps all cards in one continuous list - for Sort Alphabetically */}
                    {!loadingEmployees && !sortingByDivision && filteredEmployeeList && filteredEmployeeList.map((employee) => {
                        return (
                            <div className='card col-3' key={employee.People_ID} >
                                <Card
                                    employee={employee}
                                />
                            </div>
                        )


                    })}

                    {/* Maps all cards within division headers - for Sort By Divisions */}
                    {!loadingEmployees && sortingByDivision && employeesInDivisions && employeesInDivisions.map((division) => {
                        if (division.employees && division.employees.length !== 0) {
                            return (
                                <>
                                    <div className="division-header" key={division.Division_ID}>{division.Division_ID} - {division.Division_VC}</div>
                                    {division.employees.map((employee) => {
                                        return (
                                            <div className='card col-3' key={employee.People_ID} >
                                                <Card
                                                    employee={employee}
                                                />
                                            </div>
                                        )
                                    })}
                                </>
                            )
                        }
                    })}

                    {/* {!loadingEmployees && ((!sortingByDivision && filteredEmployeeList.length === 0) || (sortingByDivision && employeesInDivisions.length === 0)) &&
                            <div>No employees found with current search term.</div>
                        } */}
                </Row>
            </div>
        </>
    )
}
export default EmployeeDirectoryInterface;